'use strict'

###*
 # @ngdoc object
 # @name mundoReporting.service:ReportTemplateService

 # @description

###
class ReportTemplateService
  ### @ngInject ###
  constructor:(
    $log
    $filter
    $rootScope
    $q
    UiHelpers
    ReportConfigurationService
    ReportTemplateManager
    ReportFilterService
    ReportFieldService
    Restangular
    uuid4
    MundoMap
    $timeout
    _
  ) ->

    ##
    ## This is the dialog for actually opening a template before
    ## it is actually visualized.
    @openTemplateDialog = (template, source) ->

      # All filters that are active on the template itself
      templateFilters = angular.copy template.filters

      # All filters that are possible on the Source.
      sourceFilters = angular.copy source.filters

      userData = angular.copy template.userData

      if userData.tplParameters?
        tplParameters = []

      angular.forEach templateFilters, (tplFilter, key) ->
        if _.indexOf(userData.tplParameters, tplFilter.code) != -1
          filter = sourceFilters[tplFilter.code]
          filter.active = tplFilter
          filter.active.value = null
          filter.index = key
          tplParameters.push filter

      data =
        changed: false
        filters: tplParameters
        originalFilters: angular.copy sourceFilters
        state:
          progress: false

      data.remove = (key) ->
        data.filters.splice(key, 1)

      data.transformChip = (chip) ->
        return chip

      data.getFilteredChoices = (filter) ->
        return ReportFilterService
          .getChoicesByLabel template, filter, filter.active.searchQuery

      data.updateChoice = (filter) ->
        init = not filter.active.initialized
        filter.active.initialized = true

        values = filter.active.value
        values ?= []
        values = if angular.isArray values then values else [values]

        if not filter.active.selectedItem?
          filter.active.selectedItem = null

          if init and (not filter.options.multiple)
            for x in values
              ReportFilterService
                .getChoiceById template, filter, x
                .then (result) ->
                  filter.active.selectedItem = result

        if not filter.active.selectedItems?
          filter.active.selectedItems = []

          if init and filter.options.multiple
            for x in values
              ReportFilterService
                .getChoiceById template, filter, x
                .then (result) ->
                  filter.active.selectedItems.push result

        if not init
          if filter.options.multiple
            filter.active.value = []
            for x in filter.active.selectedItems
              filter.active.value.push x.id
          else
            filter.active.value = if filter.active.selectedItem? then filter.active.selectedItem.id else null

      ### Datetime filter methods ###

      data.updateDateTime = (filter) ->
        init = not filter.active.initialized
        filter.active.initialized = true

        if not filter.active.value?
          filter.active.value = [null, null]

        startValue = filter.active.value[0]
        startValue ?= (new Date()).toISOString()
        if not /[Z|+]/.test(startValue)
          startValue = "#{startValue}Z"
        startTz = startValue.match(/[Z|+].*/)[0]

        startDate = filter.active.startDate
        startDate ?= new Date(startValue)

        startTime = new Date(startDate.valueOf())
        if filter.active.startTime
          startTime = new Date("2000-01-01T#{filter.active.startTime}:00#{startTz}")
          startTime.setTime(startTime.getTime() + (startTime.getTimezoneOffset() * 60 * 1000))

        if not isNaN(startTime.getTime())
          startTime.setSeconds(0)
          startTime.setMilliseconds(0)

          startDate.setHours startTime.getHours()
          startDate.setMinutes startTime.getMinutes()
          startDate.setSeconds 0
          startDate.setMilliseconds 0

          if not filter.active.startTime?
            filter.active.startTime = moment(startTime).format('HH:mm')
          # filter.active.startTime = "#{startTime.getHours()}:#{startTime.getMinutes()}"

        endValue = filter.active.value[1]
        endValue ?= (new Date()).toISOString()
        if not /[Z|+]/.test(endValue)
          endValue = "#{endValue}Z"
        endTz = endValue.match(/[Z|+].*/)[0]

        endDate = filter.active.endDate
        endDate ?= new Date(endValue)

        endTime = new Date(endDate.valueOf())
        if filter.active.endTime
          endTime = new Date("2000-01-01T#{filter.active.endTime}:00#{endTz}")
          endTime.setTime(endTime.getTime() + (endTime.getTimezoneOffset() * 60 * 1000))

        if not isNaN(endTime.getTime())
          endTime.setSeconds(0)
          endTime.setMilliseconds(0)

          endDate.setHours endTime.getHours()
          endDate.setMinutes endTime.getMinutes()
          endDate.setSeconds 59
          endDate.setMilliseconds 999

          if not filter.active.endTime?
            filter.active.endTime = moment(endTime).format('HH:mm')
          # filter.active.endTime = "#{endTime.getHours()}:#{endTime.getMinutes()}"

        filter.active.value[0] = startDate.toISOString()
        filter.active.startDate = startDate

        filter.active.value[1] = endDate.toISOString()
        filter.active.endDate = endDate

        filter.active.timeError = isNaN(startTime.getTime()) or isNaN(endTime.getTime()) or
          (filter.active.startTime.length < 5) or (filter.active.endTime.length < 5)
        filter.active.dateError = endDate.valueOf() < startDate.valueOf()

      ### Checkbox filter methods ###
      data.updateCheckbox = (filter) ->
        filter.active.value = filter.active.operand.toLowerCase() == 'true'

      ### Location radius methods ###

      data.updateLocationRadius = (filter) ->
        init = not filter.active.initialized
        filter.active.initialized = true

        if not filter.active.mapId?
          filter.active.mapId = "location-radius-filter-map-#{uuid4.generate()}"

        if not filter.active.value?
          filter.active.value = [null, null]

        if not filter.active.value[1]?
          filter.active.value[1] = if filter.options.radius_default? then filter.options.radius_default else
            ((filter.options.radius_minimum + filter.options.radius_maximum) / 2)

        if not angular.isNumber filter.active.value[1]
          filter.active.value[1] = Number(filter.active.value[1])

        if not filter.active.value[0]?
          filter.active.value[0] = '4.5203866,51.3312898'

          if $window.navigator.geolocation?
            $window.navigator.geolocation.getCurrentPosition (position) ->
              filter.active.value[0] = "#{position.coords.longitude},#{position.coords.latitude}"
              data.updateLocationRadius(filter)
            , () ->
              # Do stuff

        coords = filter.active.value[0].split(',')
        coords[0] = parseFloat(coords[0])
        coords[1] = parseFloat(coords[1])

        point = MundoMap.getGeometryFromGeoJSON
          type: 'Point'
          coordinates: [coords[0], coords[1]]

        $timeout () ->
          if not filter.active.map?
            filter.active.map = MundoMap.createInstance filter.active.mapId,
              layers: [
                  _layerType: 'Vector'
                  _layerId: 'filters'
                  _clusterable: false
                  _searchable: false
                  _zoomable: true
                  _updateWhileMoving: true
                  title: 'Filters (Base)'
                  visible: true
                  source: [
                    'Vector'
                      features: []
                  ]
                  style: null
                ,
              ]
              follow:
                enabled: true
              search:
                enabled: false

          if not filter.active.mapLayer?
            filter.active.mapLayer = MundoMap.getLayerById filter.active.map, 'filters'
            filter.active.mapLayer.setStyle (feature, resolution) ->
              radius = filter.active.value[1] / resolution

              styles =
                'Point': [
                  new ol.style.Style
                    image: new ol.style.Circle
                      radius: 2
                      stroke: new ol.style.Stroke
                        color: 'rgba(0, 0, 0, 0.8)'
                        width: 2
                    zIndex: 65
                  new ol.style.Style
                    image: new ol.style.Circle
                      radius: radius
                      stroke: new ol.style.Stroke
                        color: 'rgba(0, 0, 0, 0.8)'
                        width: 2
                      fill: new ol.style.Fill
                        color: 'rgba(205, 255, 180, 0.5)'
                    zIndex: 60
                ]

              return styles[feature.getGeometry().getType()]

            filter.active.mapLayerSource = MundoMap.getLayerSource filter.active.mapLayer

          if not filter.active.mapPointFeature?
            filter.active.mapPointFeature = new ol.Feature(point)
            filter.active.mapDragFeatures = new ol.Collection [filter.active.mapPointFeature]

            filter.active.mapLayerSource.clear()
            filter.active.mapLayerSource.addFeature filter.active.mapPointFeature
            MundoMap.zoomAroundPoint filter.active.map, point, filter.active.value[1]

            filter.active.mapPointFeature.on 'change', (evt) ->
              coords = this.getGeometry().getCoordinates()
              coords = ol.proj.transform coords, 'EPSG:3857', 'EPSG:4326'
              filter.active.value[0] = "#{coords[0]},#{coords[1]}"

            dragInteraction = new ol.interaction.Modify
              features: filter.active.mapDragFeatures
              style: null
            filter.active.map.addInteraction dragInteraction
          else
            filter.active.mapPointFeature.setGeometry point
            filter.active.mapLayerSource.refresh()
            MundoMap.zoomAroundPoint filter.active.map, point, filter.active.value[1]

      data.executeLocationSearch = (filter) ->
        filter.active.searchNotFound = false
        Restangular.service 'services/geocode'
          .post
            address: filter.active.searchQuery
          .then (result) ->
            filter.active.value[0] = result.coordinates[0] + ',' + result.coordinates[1]
            data.updateLocationRadius(filter)
        , (error) ->
          filter.active.searchNotFound = true

      return $q (resolve, reject) ->
        UiHelpers.openDialog
          template: 'mundo-reporting/views/dialogs/report-template-open-dialog.modal.tpl.html'
          title: 'app.report.filters'
          dialogClass: 'filter-dialog'
          data:
            filtering: data
          onConfirm: (result) ->
            resolve(result)
          onCancel: () ->
            reject()

    @createTemplateFromConfiguration = (source, configuration) =>
      originalConfig = angular.copy configuration

      # Set the userdata
      if configuration.userData? && angular.isString configuration.userData
        configuration.userData = JSON.parse configuration.userData

      filterData = ReportFilterService.getFilterData source, configuration

      # Show Template settings dialog
      UiHelpers.openDialog
        template: 'mundo-reporting/views/dialogs/report-template-settings.modal.tpl.html'
        title: 'app.report.template-settings'
        dialogClass: 'template-settings-dialog'
        size: 60
        data:
          source: source
          configuration: configuration
          filtering: filterData
          basic:
            form: ReportTemplateManager.getSettingsForm configuration
            viewmode: @getViewmodeForm source
          sorting:
            form: @getSortingForm source
          fields: ReportFieldService.getFieldsData source, configuration
        onConfirm: (result) ->
          # Create an empty object we can use to patch the configuration
          patch = {}

          patch.userData = result.configuration.userData
          patch.label = result.configuration.label

          patch.filters = []
          patch.fields = []

          # Template parameters are in result.filtering.filters[]._isParameter
          angular.forEach result.filtering.filters, (filter)->

            patchFilter =
              code: filter.active.code
              operand: filter.active.operand

            if !filter._isParameter? || filter._isParameter is false
              patchFilter.value = filter.active.value

            # Add all filters, yay
            patch.filters.push patchFilter

            # Add the filter to the parameters if it's an actual parameter
            if filter._isParameter? && filter._isParameter is true
              if !patch.userData.tplParameters?
                patch.userData.tplParameters = []

              patch.userData.tplParameters.push filter.code

          patch.userData = JSON.stringify patch.userData

          patch.sort = result.configuration.sort

          # Loop over all fields
          angular.forEach result.fields.selected, (field) ->
            patch.fields.push
              field: field.field

          ReportConfigurationService.patchConfiguration configuration, patch
            .then (cfg) ->
              ReportTemplateManager.create(result.configuration).then () ->
                $rootScope.$emit "createdTemplate"

    @updateTemplate = (source, configuration) =>
      originalConfig = angular.copy configuration

      # Set the userdata
      if configuration.userData? && angular.isString configuration.userData
        configuration.userData = JSON.parse configuration.userData

      filterData = ReportFilterService.getFilterData source, configuration

      angular.forEach filterData.filters, (filter) ->
        if _.indexOf(configuration.userData.tplParameters, filter.code) != -1
          filter._isParameter = true

      # Show Template settings dialog
      UiHelpers.openDialog
        template: 'mundo-reporting/views/dialogs/report-template-settings.modal.tpl.html'
        title: 'app.report.template-settings'
        dialogClass: 'template-settings-dialog'
        size: 60
        data:
          source: source
          configuration: configuration
          filtering: filterData
          basic:
            form: ReportTemplateManager.getSettingsForm configuration
            viewmode: @getViewmodeForm source
          sorting:
            form: @getSortingForm source
          fields: ReportFieldService.getFieldsData source, configuration
        onConfirm: (result) ->
          # Create an empty object we can use to patch the configuration
          patch = {}

          patch.userData = result.configuration.userData
          patch.userData.tplParameters = []
          patch.label = result.configuration.label

          patch.filters = []
          patch.fields = []

          # Template parameters are in result.filtering.filters[]._isParameter
          angular.forEach result.filtering.filters, (filter)->

            patchFilter =
              code: filter.active.code

            if !filter._isParameter? || filter._isParameter is false
              patchFilter.operand = filter.active.operand
              patchFilter.value = filter.active.value

            # Add all filters, yay
            patch.filters.push patchFilter

            # Add the filter to the parameters if it's an actual parameter
            if filter._isParameter? && filter._isParameter is true
              if !patch.userData.tplParameters?
                patch.userData.tplParameters = []

              patch.userData.tplParameters.push filter.code

          patch.userData = JSON.stringify patch.userData

          patch.sort = result.configuration.sort

          # Loop over all fields
          angular.forEach result.fields.selected, (field) ->
            patch.fields.push
              field: field.field

          ReportConfigurationService.patchConfiguration configuration, patch
            .then (cfg) ->
              $rootScope.$emit "createdTemplate"

    @getSortingForm = (source) ->
      form = [
        type: "select"
        key: "field",
        templateOptions:
          label: "Sortering"
          labelProp: "label"
          valueProp: "code"
          options: _.values source.fields
      ,
        type: "radio"
        key: "direction"
        templateOptions:
          label: "Direction"
          labelProp: "label"
          valueProp: "id"
          options: [
            id: true
            label: "ASC"
          ,
            id: false
            label: "DESC"
          ]
      ]

      form

    @getViewmodeForm = (source) ->

      ViewMode =
        TABLE: 'TABLE'
        MAP: 'MAP'

      mappableFields = _.values source.fields
        .filter (field) ->
          return field.options? and field.options.mappable? and field.options.mappable
      mappable = mappableFields.length > 0
      options = []

      if(mappable)
        options = [
          label: "Table"
          code: ViewMode.TABLE
        ,
          label: "Map"
          code: ViewMode.MAP
        ]
      else
        options = [
          label: "Table"
          code: ViewMode.TABLE
        ]

      form = [
        type: "radio"
        key: "viewMode",
        templateOptions:
          label: "View mode"
          labelProp: "label"
          valueProp: "code"
          options: options
      ]

      form

angular
  .module('mundoReporting')
  .service 'ReportTemplateService', ReportTemplateService
